// TODO: move to foxden-version-controller-client or new repo

import { GraphQLClient } from 'graphql-request';

import { getSdk, Sdk } from '../generated/payment-graphql';
import getEnv from '../utils/getEnv';

export class PaymentGraphqlClient {
  sdk: Sdk;

  constructor(versionNumber?: string, quoteId?: string) {
    const graphQLClient = new GraphQLClient(this.getPaymentURL(versionNumber), {
      ...{ credentials: 'include' },
      ...{
        headers: {
          quoteId: quoteId || ''
        }
      }
    });
    this.sdk = getSdk(graphQLClient);
  }

  /**
   * Returns a SDK of rating-quoting backend.
   * @returns a sdk that has all APIs of rating-quoting backend.
   */
  public getSDK(): Sdk {
    return this.sdk;
  }

  public async sendTnCESignature(
    quoteId: string,
    subject: string,
    message: string
  ): Promise<boolean> {
    const result = await this.sdk.sendTnCESignature({
      quoteId,
      subject,
      message
    });

    return result.sendTnCESignature;
  }

  public async proceedToPayment(quoteId: string): Promise<string> {
    const result = await this.sdk.proceedToPayment({
      quoteId
    });

    return result.proceedToPayment;
  }

  private getPaymentURL(versionNumber?: string): string {
    const { REACT_APP_PAYMENT_URL: baseUrl } = getEnv();
    const regex = /\d{4}-\d{2}-\d{2}/g;
    return versionNumber ? baseUrl.replace(regex, versionNumber) : baseUrl;
  }
}
