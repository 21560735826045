import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** This is a string type with the constrain that the values can be one of: AB,BC,MB,NB,NF,NT,NS,NU,ON,PE,QC,SK,YT */
  AbbrevCanadaProvince: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Answers: any;
  /** Byte data, represented as a base64 encoded string */
  Byte: string;
  /** This is a string type with the constrain that the values can be one of: Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon */
  CanadaProvince: "Alberta" | "British Columbia" | "Manitoba" | "New Brunswick" | "Newfoundland and Labrador" | "Northwest Territories" | "Nova Scotia" | "Nunavut" | "Ontario" | "Prince Edward Island" | "Quebec" | "Saskatchewan" | "Yukon";
  /** This is a string type with the constrain that the values can be one of: Canada/Eastern,Canada/Atlantic,Canada/Newfoundland,Canada/Central,Canada/Mountain,Canada/Pacific */
  CanadaTimeZone: any;
  ConvertedAnswers: any;
  /** An Date formatted into ISO Date representation (date-fns' formatISO with the date representation). This scalar should be used for returning a localized string to the frontend. */
  Date: string;
  /** An Date with Time formatted into ISO Date representation. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  NonNegativeFloat: number;
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: number;
  /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
  ObjectID: string;
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: string;
  URL: any;
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: any;
};


export type AdditionalInsured = {
  __typename?: 'AdditionalInsured';
  name?: Maybe<Scalars['String']>;
  address?: Maybe<AdditionalInsuredAddress>;
  email?: Maybe<Scalars['String']>;
};

export type AdditionalInsuredAddress = {
  __typename?: 'AdditionalInsuredAddress';
  street: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['String'];
  postalCode: Scalars['PostalCode'];
  address2?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  street: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['CanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type AddressInput = {
  street: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['CanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type AmtrustEligibleQuoteResponse = {
  __typename?: 'AmtrustEligibleQuoteResponse';
  amtrustquoteId: Scalars['NonNegativeInt'];
  premium: Scalars['Float'];
  limit: WcAvailableLimit;
  availableLimit: Array<WcAvailableLimit>;
  feinIsValid: Scalars['Boolean'];
  proposalDownloadLink?: Maybe<Scalars['URL']>;
};

/** You use __typename to discriminate the types, so no data here other than GraphQL mandates one */
export type AmtrustIneligibleQuoteResponse = {
  __typename?: 'AmtrustIneligibleQuoteResponse';
  /** this should always be undefined */
  notUsed?: Maybe<Scalars['Boolean']>;
};

export type AmtrustResetCoverageRecommendationInput = {
  quoteId: Scalars['NonNegativeInt'];
};

export type AmtrustUpdateCoverageInput = {
  wcLimit: WcAvailableLimitInput;
  updatedFein?: Maybe<Scalars['String']>;
  wcQuoteId: Scalars['NonNegativeFloat'];
};

export type AmtrustUpdateCoverageOutput = {
  __typename?: 'AmtrustUpdateCoverageOutput';
  premium: Scalars['NonNegativeFloat'];
  feinIsValid?: Maybe<Scalars['Boolean']>;
  wcLimit?: Maybe<Scalars['String']>;
};


export type AnswersInput = {
  applicationId: Scalars['ObjectID'];
  answers: Scalars['Answers'];
  reasonFlag?: Maybe<Scalars['String']>;
};

export type AnswersWithIdInput = {
  applicationId: Scalars['ObjectID'];
  answers: Scalars['Answers'];
  isBrokerFlow: Scalars['Boolean'];
  reasonFlag?: Maybe<Scalars['String']>;
};

export type ApplicationBlocked = {
  __typename?: 'ApplicationBlocked';
  applicationId: Scalars['ObjectID'];
};

export type ApplicationFailure = {
  __typename?: 'ApplicationFailure';
  error: Scalars['String'];
};

export type ApplicationResponse = ApplicationSuccess | ApplicationFailure;

export enum ApplicationResultKind {
  /** This means to decline quote */
  DeclineBusiness = 'DeclineBusiness',
  /** This means go to broker flow anyway */
  SendToBroker = 'SendToBroker',
  /** This means forward to get a quote */
  OfferQuote = 'OfferQuote',
  /** This means error happen */
  Error = 'Error'
}

export type ApplicationSuccess = {
  __typename?: 'ApplicationSuccess';
  applicationId: Scalars['ObjectID'];
};

export type BusinessAddressChange = {
  __typename?: 'BusinessAddressChange';
  newAddress: UnderwritingAddress;
};


export enum CanadaChildCoverageType {
  /** Commercial General Liability */
  Cgl = 'CGL',
  /** Errors & Omissions */
  Eo = 'EO',
  /** Limited Pollution */
  LimitedPollution = 'LimitedPollution',
  /** Unmanned Aircraft */
  UnmannedAircraft = 'UnmannedAircraft',
  /** Unscheduled Business Personal PropertyP */
  UnscheduledBpp = 'UnscheduledBPP',
  /** Hardware / Software */
  Hwsw = 'HWSW',
  /** Lock Key Business Items */
  LockKeyBusinessItems = 'LockKeyBusinessItems',
  /** Unscheduled Contractors */
  UnscheduledContractors = 'UnscheduledContractors',
  /** Unscheduled hardware/software */
  UnscheduledHwsw = 'UnscheduledHWSW',
  /** Unscheduled Small Tools */
  UnscheduledSmallTools = 'UnscheduledSmallTools',
  /** Lock Key Contractors */
  LockKeyContractors = 'LockKeyContractors',
  /** Photo Video Endorsement */
  PhotoVideoEndor = 'PhotoVideoEndor',
  /** Physical Loss */
  PhysicalLoss = 'PhysicalLoss',
  /** Valuable Papers */
  ValuablePapers = 'ValuablePapers'
}

export type CanadaCoverageLimit = {
  __typename?: 'CanadaCoverageLimit';
  coverage: CanadaCoverageLimitType;
  oldLimit?: Maybe<Scalars['NonNegativeFloat']>;
  newLimit?: Maybe<Scalars['NonNegativeFloat']>;
};

/**
 * This type is mostly a copy of ChildCoverageType. However, it's needed since some child coverage has
 * multiple limits, like E&O which needs to distinguish between occurrence & aggregate limits.
 * Most limit names use the same one as the coverage name since they only have 1 limit.
 */
export enum CanadaCoverageLimitType {
  /** Commercial General Liability */
  Cgl = 'CGL',
  /** Errors & Omissions (Aggregate) */
  EoAggregate = 'EOAggregate',
  /** Errors & Omissions (Occurrence) */
  EoOccurrence = 'EOOccurrence',
  /** Limited Pollution */
  LimitedPollution = 'LimitedPollution',
  /** Unmanned Aircraft */
  UnmannedAircraft = 'UnmannedAircraft',
  /** Unscheduled Business Personal PropertyP */
  UnscheduledBpp = 'UnscheduledBPP',
  /** Hardware / Software */
  Hwsw = 'HWSW',
  /** Lock Key Business Items */
  LockKeyBusinessItems = 'LockKeyBusinessItems',
  /** Unscheduled Contractors */
  UnscheduledContractors = 'UnscheduledContractors',
  /** Unscheduled hardware/software */
  UnscheduledHwsw = 'UnscheduledHWSW',
  /** Unscheduled Small Tools */
  UnscheduledSmallTools = 'UnscheduledSmallTools',
  /** Lock Key Contractors */
  LockKeyContractors = 'LockKeyContractors',
  /** Photo Video Endorsement */
  PhotoVideoEndor = 'PhotoVideoEndor',
  /** Physical Loss */
  PhysicalLoss = 'PhysicalLoss',
  /** Valuable Papers */
  ValuablePapers = 'ValuablePapers'
}

export type CanadaCoverageUpdateChange = {
  __typename?: 'CanadaCoverageUpdateChange';
  coverage: CanadaParentCoverageType;
  action: EndorsementCoverageAction;
  limits: Array<CanadaCoverageLimit>;
};

export enum CanadaParentCoverageType {
  /** Commercial General Liability */
  Cgl = 'CGL',
  /** Inland Marine */
  Im = 'IM'
}



export enum CancellationTrigger {
  Carrier = 'carrier',
  Client = 'client'
}

/** Update CompletedApplicationResponse types in anther ticket */
export type CompletedApplicationResponse = {
  __typename?: 'CompletedApplicationResponse';
  applicationId?: Maybe<Scalars['ObjectID']>;
  errors: Array<Scalars['String']>;
  kind: ApplicationResultKind;
};


export type CoverageInput = {
  name: Scalars['String'];
  chooseCoverage: Scalars['Boolean'];
  limits: Scalars['JSONObject'];
};

export type CoveragesData = {
  __typename?: 'CoveragesData';
  name: Scalars['String'];
  chooseCoverage: Scalars['Boolean'];
  finalPremium: Scalars['Int'];
  limits: Scalars['JSONObject'];
  limitsOptions?: Maybe<Scalars['JSONObject']>;
};

/** Currencies that are supported by Foxden. */
export enum Currency {
  Cad = 'CAD',
  Usd = 'USD'
}

export type CustomerInfo = {
  __typename?: 'CustomerInfo';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CustomerInfoInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};



export type EndorsementChange = BusinessAddressChange | CanadaCoverageUpdateChange | MailingAddressChange | NamedInsuredChange | OhioCoverageUpdateChange;

export enum EndorsementCoverageAction {
  Add = 'Add',
  Remove = 'Remove',
  Update = 'Update'
}

export type FetchQuoteResponse = {
  __typename?: 'FetchQuoteResponse';
  quoteId: Scalars['ObjectID'];
  quoteNumber: Scalars['String'];
  premium: Scalars['Float'];
  carrierName: Scalars['String'];
};

export type GetJsoNbyApplicationIdResponse = {
  __typename?: 'GetJSONbyApplicationIdResponse';
  json: Scalars['JSONObject'];
  professionList: Array<Scalars['String']>;
  customerInfo: CustomerInfo;
  state?: Maybe<Scalars['String']>;
  previousAnswers?: Maybe<Scalars['ConvertedAnswers']>;
  policyStartDateStr?: Maybe<Scalars['String']>;
  policyExpiryDateStr?: Maybe<Scalars['String']>;
  wcData?: Maybe<GetJsonWcResponse>;
};

export type GetJsonWcError = {
  __typename?: 'GetJsonWcError';
  message: Scalars['String'];
};

export type GetJsonWcResponse = GetJsonWcSuccess | GetJsonWcError | GetJsonWcUnavailable;

export type GetJsonWcSuccess = {
  __typename?: 'GetJsonWcSuccess';
  rules: Array<Rule>;
  classCodes: Array<WcProfession>;
  isContractor: Scalars['Boolean'];
};

/** You should expect an empty object with __typename; GraphQL doesn't allow empty object so I need to define something */
export type GetJsonWcUnavailable = {
  __typename?: 'GetJsonWcUnavailable';
  notUsed?: Maybe<Scalars['Boolean']>;
};

export type GetQuoteError = {
  __typename?: 'GetQuoteError';
  error?: Maybe<GetQuoteErrorType>;
};

export enum GetQuoteErrorType {
  NotFound = 'NotFound',
  RequestNotRegistered = 'RequestNotRegistered',
  DeclinedQuote = 'DeclinedQuote'
}

export type GetQuoteExpired = {
  __typename?: 'GetQuoteExpired';
  quoteId: Scalars['ObjectID'];
  isQuoteExpired?: Maybe<Scalars['Boolean']>;
  isEffectiveDateExpired?: Maybe<Scalars['Boolean']>;
  isGracePeriodExpired?: Maybe<Scalars['Boolean']>;
  quoteExpireDate?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type GetQuoteOk = {
  __typename?: 'GetQuoteOk';
  quotes: Array<Quote>;
  quoteInfo: QuoteInfo;
};

export type GetRatingResultsResponse = {
  __typename?: 'GetRatingResultsResponse';
  input: Scalars['JSONObject'];
  aggregations: Scalars['JSONObject'];
};

export enum IdType {
  Claim = 'Claim',
  Invoice = 'Invoice',
  Policy = 'Policy',
  Quote = 'Quote',
  Transaction = 'Transaction'
}


export type Limit = {
  __typename?: 'Limit';
  industry: Scalars['String'];
  min: Scalars['NonNegativeInt'];
  max: Scalars['NonNegativeInt'];
};

export type MailingAddressChange = {
  __typename?: 'MailingAddressChange';
  newAddress: UnderwritingAddress;
};

export type MunichEligibleQuoteResponse = {
  __typename?: 'MunichEligibleQuoteResponse';
  quoteId: Scalars['ObjectID'];
  premium: Scalars['Float'];
  quoteNumber: Scalars['String'];
  coveragesData: Array<CoveragesData>;
  region: Scalars['String'];
  showSendQuoteButton: Scalars['Boolean'];
  disableAdditionalInsuredCheckbox: Scalars['Boolean'];
};

export type MunichIneligibleQuoteResponse = {
  __typename?: 'MunichIneligibleQuoteResponse';
  selectedCoverages: Array<Scalars['String']>;
  region: Scalars['String'];
};

export type MunichResetCoverageRecommendationInput = {
  quoteId: Scalars['ObjectID'];
  applicationId: Scalars['ObjectID'];
  region: Scalars['String'];
};

export type MunichUpdateCoverageInput = {
  quoteId: Scalars['ObjectID'];
  applicationId: Scalars['ObjectID'];
  region: Scalars['String'];
  coverage: CoverageInput;
};

export type MunichUpdateCoverageOutput = {
  __typename?: 'MunichUpdateCoverageOutput';
  quoteId: Scalars['ObjectID'];
  finalPremium: Scalars['Int'];
  coveragesData: Array<CoveragesData>;
};

export type Mutation = {
  __typename?: 'Mutation';
  noOp?: Maybe<Scalars['Boolean']>;
  startWc?: Maybe<Scalars['ObjectID']>;
  cancelPolicy: Scalars['String'];
  updateCoverage: UpdateCoverageOutput;
  resetCoverageRecommendation: UpdateCoverageOutput;
};


export type MutationStartWcArgs = {
  applicationObjectId: Scalars['ObjectID'];
  ssn?: Maybe<Scalars['String']>;
};


export type MutationCancelPolicyArgs = {
  quoteId: Scalars['ObjectID'];
};


export type MutationUpdateCoverageArgs = {
  input: UpdateCoverageInput;
};


export type MutationResetCoverageRecommendationArgs = {
  input: ResetCoverageRecommendationInput;
};

export type NamedInsuredChange = {
  __typename?: 'NamedInsuredChange';
  namedInsured: Scalars['String'];
};




export enum OhioChildCoverageType {
  /** Commercial General Liability */
  Cgl = 'CGL',
  /** Errors & Omissions */
  Eo = 'EO',
  /** Limited Pollution */
  LimitedPollution = 'LimitedPollution',
  /** Unmanned Aircraft */
  UnmannedAircraft = 'UnmannedAircraft',
  /** Terrorism Risk Insurance Act (TRIA) for GL */
  Triagl = 'TRIAGL'
}

export type OhioCoverageLimit = {
  __typename?: 'OhioCoverageLimit';
  coverage: OhioCoverageLimitType;
  oldLimit?: Maybe<Scalars['NonNegativeFloat']>;
  newLimit?: Maybe<Scalars['NonNegativeFloat']>;
};

/**
 * This type is mostly a copy of ChildCoverageType. However, it's needed since some child coverage has
 * multiple limits, like E&O which needs to distinguish between occurrence & aggregate limits.
 * Most limit names use the same one as the coverage name since they only have 1 limit.
 */
export enum OhioCoverageLimitType {
  /** Commercial General Liability (Aggregate) */
  CglAggregate = 'CGLAggregate',
  /** Commercial General Liability (Aggregate) */
  CglOccurrence = 'CGLOccurrence',
  /** Miscellaneous Errors & Omissions (Aggregate) */
  EoAggregate = 'EOAggregate',
  /** Miscellaneous Errors & Omissions (Occurrence) */
  EoOccurrence = 'EOOccurrence',
  /** Terrorism Risk Insurance Act (TRIA) for GL */
  Triagl = 'TRIAGL',
  /** Limited Pollution (Aggregate) */
  LimitedPollutionAggregate = 'LimitedPollutionAggregate',
  /** Limited Pollution (Occurrence) */
  LimitedPollutionOccurrence = 'LimitedPollutionOccurrence',
  /** Unmanned Aircraft */
  UnmannedAircraft = 'UnmannedAircraft'
}

export type OhioCoverageUpdateChange = {
  __typename?: 'OhioCoverageUpdateChange';
  coverage: OhioParentCoverageType;
  action: EndorsementCoverageAction;
  limits: Array<OhioCoverageLimit>;
};

export enum OhioParentCoverageType {
  /** Commercial General Liability */
  Cgl = 'CGL'
}

export type PolicyDates = {
  policyEffectiveDate: Scalars['Date'];
  policyExpirationDate: Scalars['Date'];
  transactionEffectiveDate: Scalars['Date'];
  transactionExpirationDate: Scalars['Date'];
};

export type PolicyDatesForRegionInput = {
  gl: PolicyDates;
  im: PolicyDates;
  region: Scalars['String'];
};


export type Query = {
  __typename?: 'Query';
  noOp?: Maybe<Scalars['Boolean']>;
  tryGetWcQuestions: TryGetWcQuestionsReponse;
  getQuote: QuoteResponse;
  fetchQuoteForCancellation: Array<FetchQuoteResponse>;
  getRatingResults: GetRatingResultsResponse;
  generateQuotePdf: Scalars['Byte'];
};


export type QueryTryGetWcQuestionsArgs = {
  applicationObjectId: Scalars['ObjectID'];
  wcSessionObjectId: Scalars['ObjectID'];
};


export type QueryGetQuoteArgs = {
  applicationId: Scalars['ObjectID'];
};


export type QueryFetchQuoteForCancellationArgs = {
  applicationId: Scalars['ObjectID'];
};


export type QueryGetRatingResultsArgs = {
  answers: Scalars['ConvertedAnswers'];
  policyDatesForRegion: PolicyDatesForRegionInput;
  quoteVersion: Scalars['String'];
  carrierPartner: Scalars['String'];
};


export type QueryGenerateQuotePdfArgs = {
  quoteId: Scalars['ObjectID'];
};

export type Quote = MunichEligibleQuoteResponse | MunichIneligibleQuoteResponse | AmtrustIneligibleQuoteResponse | AmtrustEligibleQuoteResponse;

export type QuoteInfo = {
  __typename?: 'QuoteInfo';
  customerName: Scalars['String'];
  companyName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  coverageStartDate: Scalars['String'];
  quoteExpireDate: Scalars['String'];
  transactionType: Scalars['String'];
  carrierPartner: Scalars['String'];
  dbaName?: Maybe<Scalars['String']>;
  additionalInsuredInfoList?: Maybe<Array<AdditionalInsured>>;
};

export type QuoteResponse = GetQuoteOk | GetQuoteError | GetQuoteExpired;

export type ResetCoverageRecommendationInput = {
  munichInput?: Maybe<MunichResetCoverageRecommendationInput>;
  amtrustInput?: Maybe<AmtrustResetCoverageRecommendationInput>;
};

export type Rule = {
  __typename?: 'Rule';
  officer: Scalars['String'];
  limits: Array<Limit>;
  isIncludedByDefault: Scalars['Boolean'];
  canChooseToIncludeExclude: Scalars['Boolean'];
  note: Scalars['String'];
};

export type TryGetWcQuestionsReponse = {
  __typename?: 'TryGetWcQuestionsReponse';
  /** True if the quote cannot be binded by our partners. */
  isDecline: Scalars['Boolean'];
  /** Client has to keep polling if the status is 'NotFound' */
  status: TryGetWcQuestionsStatus;
  questions: Array<WcQuestion>;
};

export enum TryGetWcQuestionsStatus {
  Error = 'Error',
  Found = 'Found',
  NotFound = 'NotFound'
}



export type UnderwritingAddress = {
  __typename?: 'UnderwritingAddress';
  street: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['AbbrevCanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type UpdateApplicationResponse = ApplicationSuccess | ApplicationFailure | ApplicationBlocked;

export type UpdateCoverageInput = {
  munichInput?: Maybe<MunichUpdateCoverageInput>;
  amtrustInput?: Maybe<AmtrustUpdateCoverageInput>;
};

export type UpdateCoverageOutput = {
  __typename?: 'UpdateCoverageOutput';
  amtrustOutput?: Maybe<AmtrustUpdateCoverageOutput>;
  munichOutput?: Maybe<MunichUpdateCoverageOutput>;
};

/** This is an enum of most of the 2-letter abbreviations of US States. Some states are missing at this time but it's good enough for our purposes. */
export enum UsState {
  Ar = 'AR',
  Az = 'AZ',
  Co = 'CO',
  Ct = 'CT',
  De = 'DE',
  Ga = 'GA',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Ut = 'UT',
  Va = 'VA',
  Vt = 'VT',
  Wi = 'WI',
  Wy = 'WY'
}

export type WcAvailableLimit = {
  __typename?: 'WcAvailableLimit';
  perAccident: Scalars['NonNegativeInt'];
  overallIllness: Scalars['NonNegativeInt'];
  perEmployeeIllness: Scalars['NonNegativeInt'];
};

export type WcAvailableLimitInput = {
  perAccident: Scalars['NonNegativeInt'];
  overallIllness: Scalars['NonNegativeInt'];
  perEmployeeIllness: Scalars['NonNegativeInt'];
};

export type WcProfession = {
  __typename?: 'WcProfession';
  id: WcProfessionId;
  description: Scalars['String'];
};

export type WcProfessionId = {
  __typename?: 'WcProfessionId';
  classCode: Scalars['String'];
  descriptionCode: Scalars['String'];
};

export type WcProfessionIdInput = {
  classCode: Scalars['String'];
  descriptionCode: Scalars['String'];
};

export type WcQuestion = {
  __typename?: 'WcQuestion';
  id: Scalars['NonNegativeInt'];
  question: Scalars['String'];
};

export type CreateApplicationAnswersInput = {
  BusinessInformation_100_Country_WORLD_EN: Scalars['String'];
  BusinessInformation_100_Province_WORLD_EN?: Maybe<Scalars['String']>;
  BusinessInformation_100_State_WORLD_EN?: Maybe<Scalars['String']>;
  BusinessInformation_100_PrimaryProfession_WORLD_EN: Scalars['String'];
  primaryProfessionLabel: Scalars['String'];
  BusinessInformation_100_CustomerInfo_WORLD_EN: CustomerInfoInput;
  BusinessInformation_100_SecondaryProfessions_WORLD_EN?: Maybe<Array<Scalars['String']>>;
  secondaryProfessionsLabels?: Maybe<Array<Scalars['String']>>;
  BusinessInformation_100_OtherProfessionCheck_WORLD_EN?: Maybe<Scalars['Boolean']>;
  BusinessInformation_100_SecondaryProfessionAdditional_WORLD_EN?: Maybe<Array<Scalars['JSONObject']>>;
  otherProfessionsLabels?: Maybe<Array<Scalars['String']>>;
  BusinessInformation_100_ProfessionsPercentage_WORLD_EN?: Maybe<Scalars['JSONObject']>;
  BusinessInformation_100_PartnerCode_WORLD_EN?: Maybe<Scalars['String']>;
  BusinessInformation_100_BrokerCode_WORLD_EN?: Maybe<Scalars['String']>;
};

export type GetFirstJsonResponse = {
  __typename?: 'getFirstJSONResponse';
  json: Scalars['JSONObject'];
  previousAnswers?: Maybe<Scalars['ConvertedAnswers']>;
};

export type IsEndorsementOrCancellationtResponse = {
  __typename?: 'isEndorsementOrCancellationtResponse';
  isEndorsement: Scalars['Boolean'];
  isCancellation: Scalars['String'];
  applicationId: Scalars['ObjectID'];
};

export type GenerateQuotePdfQueryVariables = Exact<{
  quoteId: Scalars['ObjectID'];
}>;


export type GenerateQuotePdfQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'generateQuotePdf'>
);

export type GetQuoteQueryVariables = Exact<{
  applicationId: Scalars['ObjectID'];
}>;


export type GetQuoteQuery = (
  { __typename?: 'Query' }
  & { getQuote: (
    { __typename: 'GetQuoteOk' }
    & { quotes: Array<(
      { __typename: 'MunichEligibleQuoteResponse' }
      & Pick<MunichEligibleQuoteResponse, 'premium' | 'quoteId' | 'quoteNumber' | 'region' | 'showSendQuoteButton' | 'disableAdditionalInsuredCheckbox'>
      & { coveragesData: Array<(
        { __typename?: 'CoveragesData' }
        & Pick<CoveragesData, 'chooseCoverage' | 'finalPremium' | 'limits' | 'name' | 'limitsOptions'>
      )> }
    ) | (
      { __typename: 'MunichIneligibleQuoteResponse' }
      & Pick<MunichIneligibleQuoteResponse, 'selectedCoverages' | 'region'>
    ) | { __typename: 'AmtrustIneligibleQuoteResponse' } | (
      { __typename: 'AmtrustEligibleQuoteResponse' }
      & Pick<AmtrustEligibleQuoteResponse, 'proposalDownloadLink' | 'premium' | 'feinIsValid' | 'amtrustquoteId'>
      & { limit: (
        { __typename?: 'WcAvailableLimit' }
        & Pick<WcAvailableLimit, 'overallIllness' | 'perAccident' | 'perEmployeeIllness'>
      ), availableLimit: Array<(
        { __typename?: 'WcAvailableLimit' }
        & Pick<WcAvailableLimit, 'overallIllness' | 'perAccident' | 'perEmployeeIllness'>
      )> }
    )>, quoteInfo: (
      { __typename?: 'QuoteInfo' }
      & Pick<QuoteInfo, 'customerName' | 'companyName' | 'dbaName' | 'email' | 'phoneNumber' | 'coverageStartDate' | 'quoteExpireDate' | 'transactionType' | 'carrierPartner'>
      & { additionalInsuredInfoList?: Maybe<Array<(
        { __typename?: 'AdditionalInsured' }
        & Pick<AdditionalInsured, 'name' | 'email'>
        & { address?: Maybe<(
          { __typename?: 'AdditionalInsuredAddress' }
          & Pick<AdditionalInsuredAddress, 'street' | 'city' | 'province' | 'postalCode' | 'address2'>
        )> }
      )>> }
    ) }
  ) | (
    { __typename: 'GetQuoteError' }
    & Pick<GetQuoteError, 'error'>
  ) | (
    { __typename: 'GetQuoteExpired' }
    & Pick<GetQuoteExpired, 'quoteId' | 'isQuoteExpired' | 'isEffectiveDateExpired' | 'isGracePeriodExpired' | 'quoteExpireDate' | 'country'>
  ) }
);

export type ResetCoverageRecommendationMutationVariables = Exact<{
  input: ResetCoverageRecommendationInput;
}>;


export type ResetCoverageRecommendationMutation = (
  { __typename?: 'Mutation' }
  & { resetCoverageRecommendation: (
    { __typename?: 'UpdateCoverageOutput' }
    & { amtrustOutput?: Maybe<(
      { __typename?: 'AmtrustUpdateCoverageOutput' }
      & Pick<AmtrustUpdateCoverageOutput, 'premium' | 'feinIsValid' | 'wcLimit'>
    )>, munichOutput?: Maybe<(
      { __typename?: 'MunichUpdateCoverageOutput' }
      & Pick<MunichUpdateCoverageOutput, 'finalPremium' | 'quoteId'>
      & { coveragesData: Array<(
        { __typename?: 'CoveragesData' }
        & Pick<CoveragesData, 'chooseCoverage' | 'finalPremium' | 'limits' | 'name' | 'limitsOptions'>
      )> }
    )> }
  ) }
);

export type UpdateCoverageMutationVariables = Exact<{
  input: UpdateCoverageInput;
}>;


export type UpdateCoverageMutation = (
  { __typename?: 'Mutation' }
  & { updateCoverage: (
    { __typename?: 'UpdateCoverageOutput' }
    & { amtrustOutput?: Maybe<(
      { __typename?: 'AmtrustUpdateCoverageOutput' }
      & Pick<AmtrustUpdateCoverageOutput, 'premium' | 'feinIsValid'>
    )>, munichOutput?: Maybe<(
      { __typename?: 'MunichUpdateCoverageOutput' }
      & Pick<MunichUpdateCoverageOutput, 'finalPremium' | 'quoteId'>
      & { coveragesData: Array<(
        { __typename?: 'CoveragesData' }
        & Pick<CoveragesData, 'chooseCoverage' | 'finalPremium' | 'limits' | 'name' | 'limitsOptions'>
      )> }
    )> }
  ) }
);


export const GenerateQuotePdfDocument = gql`
    query generateQuotePdf($quoteId: ObjectID!) {
  generateQuotePdf(quoteId: $quoteId)
}
    `;
export const GetQuoteDocument = gql`
    query getQuote($applicationId: ObjectID!) {
  getQuote(applicationId: $applicationId) {
    ... on GetQuoteOk {
      __typename
      quotes {
        ... on AmtrustEligibleQuoteResponse {
          __typename
          proposalDownloadLink
          premium
          feinIsValid
          amtrustquoteId
          limit {
            overallIllness
            perAccident
            perEmployeeIllness
          }
          availableLimit {
            overallIllness
            perAccident
            perEmployeeIllness
          }
        }
        ... on AmtrustIneligibleQuoteResponse {
          __typename
        }
        ... on MunichEligibleQuoteResponse {
          premium
          quoteId
          quoteNumber
          __typename
          region
          showSendQuoteButton
          coveragesData {
            chooseCoverage
            finalPremium
            limits
            name
            limitsOptions
          }
          disableAdditionalInsuredCheckbox
        }
        ... on MunichIneligibleQuoteResponse {
          __typename
          selectedCoverages
          region
        }
      }
      quoteInfo {
        customerName
        companyName
        dbaName
        email
        phoneNumber
        coverageStartDate
        quoteExpireDate
        transactionType
        carrierPartner
        additionalInsuredInfoList {
          name
          email
          address {
            street
            city
            province
            postalCode
            address2
          }
        }
      }
    }
    ... on GetQuoteError {
      __typename
      error
    }
    ... on GetQuoteExpired {
      __typename
      quoteId
      isQuoteExpired
      isEffectiveDateExpired
      isGracePeriodExpired
      quoteExpireDate
      country
    }
  }
}
    `;
export const ResetCoverageRecommendationDocument = gql`
    mutation resetCoverageRecommendation($input: ResetCoverageRecommendationInput!) {
  resetCoverageRecommendation(input: $input) {
    amtrustOutput {
      premium
      feinIsValid
      wcLimit
    }
    munichOutput {
      coveragesData {
        chooseCoverage
        finalPremium
        limits
        name
        limitsOptions
      }
      finalPremium
      quoteId
    }
  }
}
    `;
export const UpdateCoverageDocument = gql`
    mutation updateCoverage($input: UpdateCoverageInput!) {
  updateCoverage(input: $input) {
    amtrustOutput {
      premium
      feinIsValid
    }
    munichOutput {
      coveragesData {
        chooseCoverage
        finalPremium
        limits
        name
        limitsOptions
      }
      finalPremium
      quoteId
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    generateQuotePdf(variables: GenerateQuotePdfQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GenerateQuotePdfQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GenerateQuotePdfQuery>(GenerateQuotePdfDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'generateQuotePdf');
    },
    getQuote(variables: GetQuoteQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetQuoteQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetQuoteQuery>(GetQuoteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getQuote');
    },
    resetCoverageRecommendation(variables: ResetCoverageRecommendationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResetCoverageRecommendationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ResetCoverageRecommendationMutation>(ResetCoverageRecommendationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'resetCoverageRecommendation');
    },
    updateCoverage(variables: UpdateCoverageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCoverageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCoverageMutation>(UpdateCoverageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCoverage');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;