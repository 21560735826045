import { VersionControllerClient } from '@foxden/version-controller-client';

import { PaymentClient } from '../backend-client/paymentBackend';

const getTermsUrl = (baseTermsUrl: string, quoteId: string) => {
  const normalizedBaseUrl = baseTermsUrl.replace(/\/+$/, '');
  return `${normalizedBaseUrl}/terms/${quoteId}`;
};

export async function getVersionedTermsUrl(
  quoteId: string,
  versionControllerClient: VersionControllerClient
): Promise<string> {
  const version = await versionControllerClient.getVersionFromQuote(quoteId);
  const baseUrl = PaymentClient.getPaymentFrontendURL(
    version.paymentFrontendVersion
  );
  return getTermsUrl(baseUrl, quoteId);
}

const getCompleteUrl = (baseCompleteUrl: string, invoiceId: string) => {
  const normalizedBaseUrl = baseCompleteUrl.replace(/\/+$/, '');
  return `${normalizedBaseUrl}/complete/${invoiceId}`;
};

export async function getVersionedCompleteUrl(
  applicationId: string,
  invoiceId: string,
  versionControllerClient: VersionControllerClient
): Promise<string> {
  const version = await versionControllerClient.getVersionFromApplication(
    applicationId
  );
  const baseUrl = PaymentClient.getPaymentFrontendURL(
    version.paymentFrontendVersion
  );
  return getCompleteUrl(baseUrl, invoiceId);
}
