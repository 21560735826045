import './PaymentMethod.css';

import React from 'react';

import amex from '../../../../images/Payment/ae.png';
import discover from '../../../../images/Payment/discover.png';
import mastercard from '../../../../images/Payment/mastercard.png';
import stripe from '../../../../images/Payment/stripe.png';
import visa from '../../../../images/Payment/visa.png';

const PaymentMethod: React.FC = () => {
  return (
    <div
      data-testid="payment-method"
      className="payment-container w-full inline-flex gap-1.5 justify-center mb-4"
    >
      <img className="payment-details" src={stripe} alt="stripe" />
      <img className="payment-details" src={visa} alt="visa" />
      <img className="payment-details" src={mastercard} alt="mastercard" />
      <img className="payment-details" src={amex} alt="american express" />
      <img className="payment-details" src={discover} alt="discover" />
    </div>
  );
};

export default PaymentMethod;
