import { Loop } from '@material-ui/icons';
import React from 'react';

interface SpinnerProps {
  className?: string;
}
const Spinner: React.FC<SpinnerProps> = ({ className }) => (
  <div data-testid="LoopIcon" className={className}>
    <Loop className="h-full w-full inline animate-spin" />
  </div>
);

export default Spinner;
