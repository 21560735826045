import './CustomerAgreement.css';

import { VersionControllerClient } from '@foxden/version-controller-client';
import { SendSharp } from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { PaymentGraphqlClient } from '../../../../backend-client/paymentGraphqlBackend';
import Spinner from '../../../../components/Spinner';
import { useGetBrokerInfoByQuoteIdQuery } from '../../../../generated/graphql';
import getEnv from '../../../../utils/getEnv';
import { logUserActivity } from '../../../../utils/questionTracking';
import { getVersionedTermsUrl } from '../BuyNowButton/Popup';

interface CustomerAgreementProps {
  customerName: string;
  email: string;
  quoteId: string;
  paymentGraphqlClient: PaymentGraphqlClient | null;
  isTnCLinkSent: boolean;
}

const SIGN_OPTION_RADIO = 'ClientAgreeement_Quote_SelectSignOption';
const AGENT_AGREEMENT_CHECKBOX = 'ClientAgreeement_Quote_AgentAgreement';
const TNC_EMAIL_EDIT_SUBJECT_INPUT =
  'ClientAgreeement_Quote_TnCEmail_Subject_Edit';
const TNC_EMAIL_RESET_SUBJECT_INPUT =
  'ClientAgreeement_Quote_TnCEmail_Subject_Reset';
const TNC_SEND_ESIGN_BUTTON = 'ClientAgreeement_Quote_SendTnCESign';
const COMMUNICATION_AGREEMENT_CHECKBOX =
  'ClientAgreeement_Quote_CommunicationAgreement';
const REDIRECT_TO_ESIGN_BUTTON = 'ClientAgreeement_Quote_ProceedToESign';
const REDIRECT_TO_PAYMENT_BUTTON = 'ClientAgreeement_Quote_ProceedToPayment';

const { REACT_APP_VERSION_CONTROLLER_GRAPHQL_URL } = getEnv();

const vcc = new VersionControllerClient(
  REACT_APP_VERSION_CONTROLLER_GRAPHQL_URL
);

const CustomerAgreement: React.FC<CustomerAgreementProps> = ({
  quoteId,
  customerName,
  email,
  paymentGraphqlClient,
  isTnCLinkSent
}) => {
  const [signOption, setSignOption] = useState('broker-sign');
  const [checkAgentAgreement, setCheckAgentAgreement] = useState(false);
  const [
    checkCommunicationAgreement,
    setCheckCommunicationAgreement
  ] = useState(false);
  const [termsUrl, setTermsUrl] = useState('');

  const DEFAULT_EMAIL_SUBJECT = !isTnCLinkSent
    ? 'ACTION REQUIRED: Please sign application for purchase of Foxquilt business insurance'
    : 'ACTION REQUIRED: Reminder: Please sign application for purchase of Foxquilt business insurance';
  const [subject, setSubject] = useState(DEFAULT_EMAIL_SUBJECT);

  // sendTnCEmail API
  const [isSendTnCESignatureLoading, setIsSendTnCESignatureLoading] = useState(
    false
  );
  const [sendTnCESignatureSucceed, setSendTnCESignatureSucceed] = useState<
    boolean | undefined
  >(undefined);
  const [sendTnCESignatureError, setSendTnCESignatureError] = useState<
    string | undefined
  >(undefined);
  // proceedToPayment API
  const [isProceedToPaymentLoading, setIsProceedToPaymentLoading] = useState(
    false
  );
  const [proceedToPaymentError, setProceedToPaymentError] = useState<
    string | undefined
  >(undefined);

  const [questionStartTime, setQuestionStartTime] = useState<Date | null>(null);

  const [cookies] = useCookies(['hubspotutk']);
  const hubspotTracker = cookies.hubspotutk;

  const customerFirstName = customerName ? customerName.split(' ')[0] : 'there';
  const defaultMessage = `Hi ${customerFirstName},\nPlease e-sign the application for your business insurance policy through Foxquilt Insurance Services.\n[button: Click to E-Sign Application]\n\nCoverage will be bound and your policy will be issued once both the application and the payment has been received. Both items must be received within 14 days so please action this promptly. Once this application is signed, if payment has not already been received, you will be prompted for payment.\n\nIf the required steps are not completed within 14 days of when the purchase process was started, any payment we've collected will be refunded and you will need to work with your agent to get a new business insurance quote.\n\nIf you have any questions, please contact your insurance agent.`;

  const sendForESignatureOnClick = () => {
    logUserActivity({
      questionId: TNC_SEND_ESIGN_BUTTON,
      questionAnswer: 'Send for e-Signature button clicked',
      hubspotTracker
    });
    if (quoteId && !isSendTnCESignatureLoading && paymentGraphqlClient) {
      setIsSendTnCESignatureLoading(true);
      paymentGraphqlClient
        .sendTnCESignature(quoteId, subject, defaultMessage)
        .then((data) => {
          const res = data.valueOf();
          setSendTnCESignatureSucceed(res);
          setSendTnCESignatureError(undefined);
        })
        .catch((err) => {
          setSendTnCESignatureSucceed(false);
          setSendTnCESignatureError(err);
        })
        .finally(() => {
          setIsSendTnCESignatureLoading(false);
        });
    }
  };

  const redirectToESignOnClick = () => {
    logUserActivity({
      questionId: REDIRECT_TO_ESIGN_BUTTON,
      questionAnswer: 'Redirect to e-sign button clicked',
      hubspotTracker
    });
    window.location.href = termsUrl;
  };

  const proceedToPaymentOnClick = () => {
    logUserActivity({
      questionId: REDIRECT_TO_PAYMENT_BUTTON,
      questionAnswer: 'Redirect to payment button clicked',
      hubspotTracker
    });
    if (quoteId && !isProceedToPaymentLoading && paymentGraphqlClient) {
      setIsProceedToPaymentLoading(true);
      paymentGraphqlClient
        .proceedToPayment(quoteId)
        .then((data) => {
          const res = data.valueOf();
          setProceedToPaymentError(undefined);
          setIsProceedToPaymentLoading(false);
          window.location.href = res;
        })
        .catch((err) => {
          setProceedToPaymentError(err);
          setIsProceedToPaymentLoading(false);
        });
    }
  };

  const {
    data: getBrokerInfoDataResult,
    loading: getBrokerInfoLoading
  } = useGetBrokerInfoByQuoteIdQuery({
    variables: {
      quoteId
    }
  });

  const isLoading =
    signOption === 'client-sign' &&
    (getBrokerInfoLoading || !getBrokerInfoDataResult);

  useEffect(() => {
    const updateTermsUrl = async () => {
      if (!termsUrl) {
        const termsUrl = await getVersionedTermsUrl(quoteId, vcc);
        setTermsUrl(termsUrl);
      }
    };

    updateTermsUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    logUserActivity({
      questionId: SIGN_OPTION_RADIO,
      questionAnswer: signOption,
      hubspotTracker
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signOption]);

  useEffect(() => {
    logUserActivity({
      questionId: AGENT_AGREEMENT_CHECKBOX,
      questionAnswer: checkAgentAgreement,
      hubspotTracker
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAgentAgreement]);

  useEffect(() => {
    logUserActivity({
      questionId: COMMUNICATION_AGREEMENT_CHECKBOX,
      questionAnswer: checkCommunicationAgreement,
      hubspotTracker
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCommunicationAgreement]);

  const BrokerAgreement = (
    <div>
      <p className="term-description">
        <b>How It Works: </b>Foxquilt uses Docusign to provide a safe and secure
        method for electronically signing the required documents. After checking
        off the agreement below and submitting, you will be directed to Docusign
        to electronically sign on behalf of your client.
      </p>
      <hr className="border-solid border-gray-500 mt-4 mb-4" />
      <h2 className="term-title">Agent/Broker E-Signature Agreement</h2>
      <p className="term-description mt-2">
        My customer agrees that I will electronically sign their documents on
        their behalf.
      </p>
      <div
        className="term-check"
        onClick={() => {
          setCheckAgentAgreement(!checkAgentAgreement);
        }}
      >
        <input
          data-testid="agent-agreement-checkbox"
          type="checkbox"
          checked={checkAgentAgreement}
          onChange={() => {
            setCheckAgentAgreement(!checkAgentAgreement);
          }}
        />
        <label>The customer understands and agrees.</label>
      </div>
    </div>
  );

  const CustomerInfo = (
    <Fragment>
      {sendTnCESignatureSucceed ? (
        <div className="term-container mb-0">
          <h2 className="term-title text-secondary">
            Documents Emailed to client!
          </h2>
          <p className="term-description">
            If the client has not received the documents, we recommend that they
            check their spam folder.
          </p>
          <p className="term-description">
            We will notify you by email when your client completes the
            e-signature.
          </p>
          <p className="term-description">
            You can continue to purchase and select your client&apos; payment
            preferences while waiting for their e-signature.
          </p>
        </div>
      ) : (
        <div>
          <p className="term-description">
            <b>How It Works: </b>Foxquilt uses Docusign to provide a safe and
            secure method for your client to electronically sign their
            documents. At no extra cost, your client will receive a copy of
            their documents requiring signatures that they can sign online and
            submit back to Foxquilt. You will be notified by email as soon as
            your client has signed their documents.
          </p>
          <hr className="border-solid border-gray-500 mt-4 mb-4" />
          <div className="customer-info flex flex-col space-y-3 mt-4 sm:text-base text-sm">
            <h2 className="sm:text-2xl text-xl text-gray-500">
              <b>Customer&apos;s Info</b>
            </h2>
            <hr className="border-solid border-gray-500 mt-2 mb-2" />
            <span>
              <b className="mr-2">Customer&apos;s Name:</b>
              {customerName}
            </span>
            <span>
              <b className="mr-2">Customer&apos;s Email Address:</b>
              {email}
            </span>
          </div>
          <div className="agent-info mt-6 flex flex-col space-y-3 sm:text-base text-sm">
            <h2 className="sm:text-2xl text-xl text-gray-500">
              <b>Agent/Broker Info</b>
            </h2>
            <hr className="border-solid border-gray-500 mt-2 mb-2" />
            <div className="grid grid-cols-3 gap-4">
              <b className="col-span-1 mt-4 mr-2">Agent/Broker Name:</b>
              <input
                data-testid="broker-name"
                className="col-span-2 border-solid border-gray-500 border-lg"
                value={
                  getBrokerInfoDataResult?.getBrokerInfoByQuoteId.name || ''
                }
                type="text"
                readOnly={true}
              />
              <b className="col-span-1 mt-4 mr-2">
                Agent/Broker Email Address:
              </b>
              <input
                data-testid="broker-email"
                className="col-span-2 border-solid border-gray-500 border-lg"
                value={
                  getBrokerInfoDataResult?.getBrokerInfoByQuoteId.email || ''
                }
                type="text"
                readOnly={true}
              />
              <b className="col-span-1 mt-4 mr-2">Subject:</b>
              <input
                data-testid="subject"
                className="col-span-2 border-solid border-gray-500 border-lg"
                value={subject}
                type="text"
                onChange={(e) => setSubject(e.target.value)}
                onFocus={() => {
                  setQuestionStartTime(new Date());
                }}
                onBlur={() => {
                  logUserActivity({
                    questionId: TNC_EMAIL_EDIT_SUBJECT_INPUT,
                    questionAnswer: subject,
                    questionStartTime,
                    hubspotTracker
                  });
                }}
              />
              <div className="col-span-1 sm:block hidden" />
              <p className="col-span-2 text-faded">
                Subject will also be the e-signature envelope name
              </p>
              {subject === DEFAULT_EMAIL_SUBJECT && (
                <div className="col-span-1 sm:block hidden" />
              )}
              {subject === '' ? (
                <>
                  <div className="col-span-1 sm:block hidden" />
                  <p className="col-span-1 text-alert">
                    Subject field cannot be blank.
                  </p>
                </>
              ) : (
                <div className="col-span-1 sm:block hidden" />
              )}
              {subject !== DEFAULT_EMAIL_SUBJECT ? (
                <button
                  data-testid="reset-subject"
                  className={`col-span-${
                    subject === '' ? '1' : '2'
                  } reset-input`}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    setSubject(DEFAULT_EMAIL_SUBJECT);
                    logUserActivity({
                      questionId: TNC_EMAIL_RESET_SUBJECT_INPUT,
                      questionAnswer: DEFAULT_EMAIL_SUBJECT,
                      hubspotTracker
                    });
                  }}
                >
                  {'Reset to default text.'}
                </button>
              ) : (
                <div className="col-span-1 sm:block hidden" />
              )}
              <div className="col-span-1 sm:hidden block" />
              <b className="col-span-1 mt-4 mr-2">Message to Client:</b>
              <textarea
                data-testid="message"
                className="col-span-2 border-solid border-gray-500 border-lg h-60"
                value={defaultMessage}
                readOnly={true}
              />
              <div className="col-span-1 sm:block hidden" />
              <div className="sm:col-span-1 col-span-2">
                <button
                  className="send-signature text-left px-6 py-4 rounded-md text-white flex flex-row"
                  disabled={subject === ''}
                  onClick={sendForESignatureOnClick}
                >
                  {isSendTnCESignatureLoading ? (
                    <>
                      <Spinner className="mx-auto h-6 w-6 text-white mr-2" />
                      Sending...
                    </>
                  ) : (
                    <>
                      <SendSharp className="mr-2" />
                      Send for e-Signature
                    </>
                  )}
                </button>
                {sendTnCESignatureSucceed === false ? (
                  <span className="text-alert">Your Quote has expired.</span>
                ) : undefined}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );

  if (sendTnCESignatureError) {
    throw Error('Error sending TnC e-signature');
  }

  if (proceedToPaymentError) {
    throw Error('Failed to redirect to payment');
  }

  return (
    <div className="customer-agreement-container">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="custom-description w-full">
          To complete policy purchase, the application must be signed and
          payment must be received.
        </div>
        <div className="term-container">
          <p className="term-description">
            To sign the application, Foxquilt offers two options:
          </p>
          <div
            className="term-check"
            onClick={() => {
              setSignOption('broker-sign');
            }}
          >
            <input
              type="radio"
              data-testid="broker-sign"
              name="sign_option"
              value="broker-sign"
              checked={signOption === 'broker-sign'}
              onChange={() => setSignOption('broker-sign')}
            />
            <label>
              You can instantly sign the application on your client&apos;s
              behalf.
            </label>
          </div>
          <div
            className="term-check"
            onClick={() => setSignOption('client-sign')}
          >
            <input
              type="radio"
              data-testid="client-sign"
              name="sign_option"
              value="client-sign"
              checked={signOption === 'client-sign'}
              onChange={() => setSignOption('client-sign')}
            />
            <label>
              Foxquilt will send the application to your client for their
              e-signature.
            </label>
          </div>
          {isLoading ? (
            <Spinner className="mx-auto h-12 w-12 text-gray-400" />
          ) : signOption === 'broker-sign' ? (
            BrokerAgreement
          ) : (
            CustomerInfo
          )}
        </div>
        <div className="term-container">
          <h2 className="term-title">Electronic Communication Agreement</h2>
          <p className="term-description">
            My customer agrees to accept delivery of the insurance policy and
            related documents via email to address provided to Foxquilt.
          </p>
          <div
            className="term-check"
            onClick={() => {
              setCheckCommunicationAgreement(!checkCommunicationAgreement);
            }}
          >
            <input
              data-testid="communication-agreement-checkbox"
              type="checkbox"
              checked={checkCommunicationAgreement}
              onChange={() => {
                setCheckCommunicationAgreement(!checkCommunicationAgreement);
              }}
            />
            <label>The customer understands and agrees.</label>
          </div>
        </div>
        <div>
          {signOption === 'broker-sign' ? (
            <Fragment>
              <button
                disabled={!checkAgentAgreement || !checkCommunicationAgreement}
                className="proceed-payment text-left px-6 py-4 rounded-md text-white uppercase font-bold mr-2"
                onClick={redirectToESignOnClick}
              >
                Proceed to e-Sign
              </button>
              After e-signing the application, you will proceed to payment.
            </Fragment>
          ) : (
            <button
              disabled={
                !checkCommunicationAgreement || !sendTnCESignatureSucceed
              }
              className="proceed-payment text-left px-6 py-4 rounded-md text-white uppercase font-bold mr-2 flex flex-row"
              onClick={proceedToPaymentOnClick}
            >
              {isProceedToPaymentLoading ? (
                <>
                  <Spinner className="mx-auto h-6 w-6 text-white mr-2" />
                  Proceeding...
                </>
              ) : (
                'Proceed to Payment'
              )}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CustomerAgreement;
