import { differenceInSeconds } from 'date-fns';

import getEnv from './getEnv';

const { REACT_APP_QUESTION_TRACKING_URL } = getEnv();

export interface QuestionFields {
  questionId: string | null;
  questionAnswer: unknown;
  totalTimeSpent?: number;
  hubspotTracker?: string;
  timestamp?: string;
}

export const onBlurEvent = (
  QuestionTrackingData: QuestionFields,
  questionStartTime: Date | null
): void => {
  if (questionStartTime === null) {
    return;
  }

  if (
    QuestionTrackingData.questionId != null &&
    QuestionTrackingData.questionId != ''
  ) {
    const question_out_time = new Date();
    QuestionTrackingData.totalTimeSpent = differenceInSeconds(
      question_out_time,
      questionStartTime
    );
    storeField(QuestionTrackingData);
  }
};

interface LogUserActivity {
  questionId: string;
  questionAnswer: unknown;
  questionStartTime?: Date | null;
  hubspotTracker?: string;
}

export const logUserActivity = ({
  questionId,
  questionAnswer,
  questionStartTime,
  hubspotTracker
}: LogUserActivity): void => {
  onBlurEvent(
    {
      questionId,
      questionAnswer,
      hubspotTracker: hubspotTracker
    },
    questionStartTime ? questionStartTime : new Date()
  );
};

export const storeField = (QuestionTrackingData: QuestionFields): void => {
  QuestionTrackingData.timestamp = new Date().toISOString();
  try {
    fetch(REACT_APP_QUESTION_TRACKING_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(QuestionTrackingData)
    });
  } catch (e) {}
};

export const scrollToElement = (element: HTMLElement | null): void => {
  element?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center'
  });
};
